import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/erencem.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillFacebook,
  AiFillLinkedin,
  AiFillMail,
  AiFillUpCircle
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="myAvtar">
          
            <Tilt>
              <img src={myImg} className="img-fluid" style={{ height:250, width: 250}} alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
          <h1 style={{ fontSize: "2.6em", color: "white" }}>
              EREN CEM ULUSAL
            </h1>
            <ul className="home-about-social-links">

            <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/eren-cem-ulusal-4a6b5a165/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillLinkedin />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://instagram.com/erencem.ulusal"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://x.com/erencemulusal"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.facebook.com/erencemulusal"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="mailto:erencemulusal@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillMail/>
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.guyad.org/TR,1314/eren-cem-ulusal.html"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillUpCircle/>
                </a>
              </li>
             
             
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
